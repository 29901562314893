import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import ServiceBlock from "../components/Index/ServiceBlock"
import HeroCarousel from "../components/Index/Hero"
import PortfolioBlock from "../components/Index/PortfolioBlock"
import PromoBlock from "../components/Index/PromoBlock"
import IntroBlock from "../components/Index/IntroBlock"



const IndexPage = (props) => {
  const { location, data } = props
  const path = location.pathname

  const page = data.contentfulHomePage

  let sharingImage = false
  if (page.seo.socialMediaImage) {
    sharingImage = page.seo.socialMediaImage.file.url
  }

  return (
    <Layout>
      <Seo
        title={page.seo.metaTitle}
        description={page.seo.metaDescription}
        fullTitle={true}
        path={path}
        image={sharingImage}
      />
      <HeroCarousel text={page.hero.title} slides={page.hero.slides} />
      <IntroBlock blocks={page.introBlock} heading={page.heading} categories={page.headingIteratingCategories}/>
      <PortfolioBlock blocks={page.portfolioBlock} />
      <PromoBlock blocks={page.promoBlock} />
      <ServiceBlock blocks={page.serviceBlock} />
    </Layout>
  )
}

export default IndexPage

export const indexQuery = graphql`
  query IndexQuery {
    contentfulHomePage(slug: { eq: "home" }) {
      heading
      headingIteratingCategories
      hero {
        ...carouselHeroFragment
      }
      introBlock {
        image {
          description
          gatsbyImageData(width: 1133)
        }
        imagePosition
        content {
          raw
        }
        title
      }
      portfolioBlock {
        ...portfolioCardFragment
      }
      promoBlock {
        ...promoModuleFragment
      }
      seo {
        ...seoFragment
      }
      serviceBlock {
        icon {
          description
          file {
            url
          }
        }
        text
      }
    }
  }
`;
