import React, { Fragment, useEffect, useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";
import fonts from "../../styles/fonts";
import BlockContainer from "../Core/Containers/BlockContainer";
import { Row, Col } from "styled-bootstrap-grid";
import Slider from "react-slick";

const HeroContainer = styled.div`
  padding: 0 25px;
  @media (min-width: ${breakpoints.md}) {
    padding: 0;
  }
`;

const DesktopImage = styled(GatsbyImage)`
  display: none !important;

  @media (min-width: ${breakpoints.md}) {
    display: block !important;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  @media (min-width: 1443px) {
    border-radius: 20px;
  }
`;

const MobileImage = styled(GatsbyImage)`
  display: block !important;

  @media (min-width: ${breakpoints.md}) {
    display: none !important;
  }
`;

const CopyCol = styled(Col)`
  @media (min-width: ${breakpoints.md}) {
    padding: 0 30px 0 25px;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding: 0 63px 0 100px;
  }
`;

const Image = styled.div`
  position: ${(props) => props.position};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 2s ease;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const ImageContainer = styled.div`
  position: relative;
  margin-top: 60px;
  border-radius: 10px;
  overflow: hidden;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  @media (min-width: 1443px) {
    border-radius: 20px;
  }
`;

const Hero = ({ className, text = null, slides }) => {
  const [active, setActive] = useState(0);

  let indexCount = 0;

  const cycleImages = () => {
    indexCount = indexCount < slides.length ? indexCount : 0;
    setActive(indexCount);
    indexCount++;
  };

  useEffect(() => {
    cycleImages();
    setInterval(cycleImages, 4500);
    return () => {
      clearInterval(cycleImages, 4500);
    };
  }, []);

  return (
    <BlockContainer smMargin>
      <HeroContainer className={className}>
        <Row alignItems="center">
          <CopyCol md={6}>{text && <h1>{text}</h1>}</CopyCol>
          <Col md={6}>
            <ImageContainer>
              {slides.map(({ image, imageMobile }, index) => {
                return (
                  <Image
                    position={index > 0 ? "absolute" : "relative"}
                    visible={index === active ? 1 : 0}
                    key={index}
                  >
                    <DesktopImage
                      image={image.gatsbyImageData}
                      alt={image.description || ""}
                    />
                    <MobileImage
                      image={
                        imageMobile
                          ? imageMobile.gatsbyImageData
                          : image.gatsbyImageData
                      }
                      alt={image.description || ""}
                    />
                  </Image>
                );
              })}
            </ImageContainer>
          </Col>
        </Row>
      </HeroContainer>
    </BlockContainer>
  );
};

export default Hero;
