import React, { Fragment, useEffect } from "react";
import BlockContainer from "../../Core/Containers/BlockContainer";
import ContentContainer from "../../Core/Containers/ContentContainer";
import styled from "styled-components";
import breakpoints from "../../../styles/breakpoints";
import PortfolioCard from "./components/PortfolioCard";
import InView from "../../Core/InView";

const TitleSection = styled.div`
  margin-bottom: 24px;

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 40px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  grid-auto-rows: 1fr;

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 24px;
    row-gap: 58px;
  }

  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const Intersection = styled.div`
  .reveal {
    opacity: 0;
    transition: 1s all ease;
    transform: translateY(10px);
  }
  .reveal.active {
    opacity: 1;
    transform: translateY(0%);
  }
`;

const PortfolioBlock = ({ blocks }) => {
  return (
    <BlockContainer>
      <ContentContainer>
        <Intersection>
          <InView>
            <TitleSection>
              <h2>Our Work</h2>
            </TitleSection>
          </InView>
        </Intersection>
        <Grid>
          {blocks.map((block, index) => {
            return (
              <Intersection key={index}>
                <InView key={index}>
                  <PortfolioCard {...block} />
                </InView>
              </Intersection>
            );
          })}
        </Grid>
      </ContentContainer>
    </BlockContainer>
  );
};

export default PortfolioBlock;
