import React, { Fragment, useEffect, useState } from "react";
import BlockContainer from "../../Core/Containers/BlockContainer";
import ContentContainer from "../../Core/Containers/ContentContainer";
import styled from "styled-components";
import breakpoints from "../../../styles/breakpoints";
import IntroCard from "./components/IntroCard";
import InView from "../../Core/InView";
import { H2Style, H3Style } from "../../Core/Styles/typography";

const Container = styled.div`
  max-width: 100vw;
  overflow: hidden;
  @media (min-width: ${breakpoints.md}) {
    .mobile {
      display: none;
    }
    margin-top: -100px;
    margin-bottom: 150px;
  }
`;

const HeadingContainer = styled.div`
  margin-bottom: 100px;
  position: relative;

  h2 {
    ${H3Style};
  }

  @media (min-width: ${breakpoints.md}) {
    margin: 100px auto 100px;
    z-index: 1;
    h2 {
      ${H2Style};
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    max-width: 552px;
    white-space: nowrap;
    .reveal {
      opacity: 0;
      transition: 1s all ease;
      transform: translateY(10px);
    }

    .reveal.active {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const Grid = styled.div`
  display: grid;
  gap: 100px;

  @media (min-width: ${breakpoints.md}) {
    gap: 280px;
  }
`;

const AnimatedCategory = styled.span`
  opacity: ${(props) => (props.visible ? 1 : 0)};
  position: ${(props) => props.position};
  top: 50%;
  transform: translateY(-50%);
  left: ${(props) => (props.position === "absolute" ? "0" : "0")};
`;

const AnimatedContainer = styled.div`
  display: inline-block;
  position: relative;
`;

const Spacer = styled.span`
  display: inline-block;
  padding-right: 6px;

  @media (min-width: ${breakpoints.md}) {
    padding-right: 12px;
  }
`;

const IntroBlock = ({ blocks, heading, categories }) => {
  const [active, setActive] = useState(0);
  let firstWord;
  if (categories && heading) {
    const init = heading.indexOf("[");
    const fin = heading.indexOf("]");
    firstWord = heading.substr(init + 1, fin - init - 1);

    if (!categories.includes(firstWord)) {
      categories.unshift(firstWord);
    }
  }

  let indexCount = 0;

  const cycleHeadingCategories = () => {
    indexCount = indexCount < categories.length ? indexCount : 0;
    setActive(indexCount);
    indexCount++;
  };

  useEffect(() => {
    if (firstWord) {
      cycleHeadingCategories();
      setInterval(cycleHeadingCategories, 3000);
      return () => {
        clearInterval(cycleHeadingCategories, 3000);
      };
    }
  }, []);

  const getHeadingText = (type) => {
    const init = heading.indexOf("[");
    const fin = heading.indexOf("]");
    const before = heading.substr(0, init - 1);
    const after = heading.substr(fin + 1, heading.length - 1);

    if (type === "before") {
      return before;
    } else {
      return after;
    }
  };

  getHeadingText();

  return (
    <Container>
      <BlockContainer visibleOverflow>
        <ContentContainer>
          <HeadingContainer>
            <InView>
              <h2>
                {firstWord ? (
                  <>
                    {" "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: getHeadingText("before"),
                      }}
                    />
                    {categories && categories.length > 0 && <Spacer />}
                    <AnimatedContainer>
                      {categories.map((category, index) => {
                        const categoriesCopy = categories.map(
                          (category) => category
                        );
                        const longestWord = categoriesCopy.sort(
                          (a, b) => b.length - a.length
                        )[0];
                        return (
                          <AnimatedCategory
                            visible={index === active ? 1 : 0}
                            position={
                              longestWord === category ? "relative" : "absolute"
                            }
                          >
                            {category}
                          </AnimatedCategory>
                        );
                      })}
                    </AnimatedContainer>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: getHeadingText("after"),
                      }}
                    />
                  </>
                ) : (
                  heading
                )}
              </h2>
            </InView>
          </HeadingContainer>
        </ContentContainer>
        <Grid>
          {blocks.map((block, index) => {
            return (
              <Fragment key={index}>
                <IntroCard
                  {...block}
                  arrayLength={blocks.length}
                  index={index}
                />
              </Fragment>
            );
          })}
        </Grid>
      </BlockContainer>
    </Container>
  );
};

export default IntroBlock;
