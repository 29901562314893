import React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import RichText from "../../../Core/RichText";
import { Row, Col } from "styled-bootstrap-grid";
import breakpoints from "../../../../styles/breakpoints";
import InView from "../../../Core/InView";

const CardContainer = styled.div`
  padding: 0 26px;


  @media (min-width: ${breakpoints.md}) {
    padding: 0 75px;
  }
`;

const StyledRow = styled(Row)`
  display: flex;
`;

const ImageCol = styled(Col)`
  position: relative;

  .reveal {
    opacity: 0;
    transition: 1s all ease;
    transform: ${(props) =>
      props.left ? "translateX(-2%)" : "translateX(2%)"};
  }
  .reveal.active {
    opacity: 1;
    transform: translateX(0%);
  }

  @media (min-width: ${breakpoints.md}) {
    height: inherit;
  }
`;

const ContentCol = styled(Col)`
  .reveal {
    opacity: 0;
    transition: 1s all ease;
  }

  .reveal.active {
    opacity: 1;
  }

  @media (min-width: ${breakpoints.md}) {
    display: flex;
    justify-content: ${(props) => (props.left ? "flex-end" : "flex-start")};
  }
`;

const StyledImage = styled(GatsbyImage)`
  max-height: 300px;
  display: flex;
  justify-content: center;

  img {
    object-fit: contain !important;

  }

  @media (min-width: ${breakpoints.md}) {

    max-height: ${(props) =>
      props.index === "0" ? "480px" : props.index === "1" ? "630px" : "430px"};
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    ${(props) =>
      !props.left
        ? "right: -80%"
        : props.index === "0"
        ? "left: -80%"
        : "left: -50%"};
  }

  @media (min-width: ${breakpoints.lg}) {
    max-height: ${(props) =>
      props.index === "0" ? "780px" : props.index === "1" ? "930px" : "730px"};
  }
`;

const Content = styled.div`
  margin-top: 40px;

  @media (min-width: ${breakpoints.md}) {
    width: 360px;
    margin-top: 0;
  }
`;

const Text = styled.div`
  margin-top: 12px;
  @media (min-width: ${breakpoints.md}) {
    margin-top: 24px;
  }
`;

const IntroCard = ({
  image,
  imagePosition,
  content,
  title,
  index,
  arrayLength,
}) => {

  return (
    <CardContainer left={imagePosition === "Left" ? 1 : 0}>
        <StyledRow>
          <ImageCol
            md={index === 2 ? 7 : 5}
            mdOrder={imagePosition === "Left" ? "first" : "last"}
            index={index.toString()}
            left={imagePosition === "Left" ? 1 : 0}
          >
            <InView style={{width: '100%', height: '100%'}}>
              <StyledImage
                image={image.gatsbyImageData}
                alt={image.description || title}
                left={imagePosition === "Left" ? 1 : 0}
                index={index.toString()}
              />
            </InView>
          </ImageCol>
          <ContentCol
            md={index === 2 ? 5 : 7}
            left={imagePosition === "Right" ? 1 : 0}
          >
            <InView>
              <Content>
                <h3>{title}</h3>
                <Text>
                  <RichText content={content} />
                </Text>
              </Content>
            </InView>
          </ContentCol>
        </StyledRow>
    </CardContainer>
  );
};

export default IntroCard;
