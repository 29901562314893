import React from "react"
import Image from "../Core/Image"
import styled from "styled-components"
import { H5Style } from "../Core/Styles/typography"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"

const Number = styled.h4`
  ${fonts.sourceSerif};
  font-size:60px;
  line-height: 82px;
`

const Text = styled.p`
  ${H5Style};
`

const Entry = styled.div`
  display: flex;
  img {
    margin-right: 24px;
  }

  @media (min-width: ${breakpoints.lg}) {
    display: grid;
    gap: 13px;

    img {
      margin-right: 0;
    }
  }
`

const Details = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr;
  column-gap: 16px;
  align-items: center;
`

const ServiceEntry = ({number, text, icon, className}) => {
  return (
    <Entry className={className}>
      <img src={icon.file.url} alt={icon.description} />
      <Details>
        <div>
          <Number>{number}</Number>
        </div>
        <div>
          <Text>
            {text}
          </Text>
        </div>
      </Details>
    </Entry>
  );
}

export default ServiceEntry
