import React, { useEffect } from "react";
import styled from "styled-components";
import ServiceEntry from "./ServiceEntry";
import BlockContainer from "../Core/Containers/BlockContainer";
import ContentContainer from "../Core/Containers/ContentContainer";
import breakpoints from "../../styles/breakpoints";
import { ReactComponent as CurlyArrowDown } from "../../resources/img/svg/arrows/curly-arrow-down.svg";
import { ReactComponent as CurlyArrowUp } from "../../resources/img/svg/arrows/curly-arrow-up.svg";
import InView from "../Core/InView";
const TitleBlock = styled.div`
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 40px;
  }
`;

const ServiceList = styled.ul`
  li {
    margin-bottom: 12px;

    :last-of-type {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;

    li {
      margin-bottom: 0;
      .entry-content {
        position: relative;
      }

      :nth-of-type(even) {
        margin-top: 180px;
      }
    }
  }
`;

const ArrowContainer = styled.div`
  display: none;
  position: absolute;

  @media (min-width: ${breakpoints.lg}) {
    display: block;
    ${(props) =>
      props.even ? "top: -50px; right: 25px" : "bottom: -80px; right: 25px"};
  }
  @media (min-width: ${breakpoints.xl}) {
    ${(props) =>
      props.even ? "top: -50px; right: 50px" : "bottom: -80px; right: 50px"};
  }
`;

const Intersection = styled.div`
  .reveal {
    opacity: 0;
    transition: 1s all ease;
    transform: translateY(10px);
  }
  .reveal.active {
    opacity: 1;
    transform: translateY(0%);
  }
`;

const ServiceBlock = ({ blocks }) => {
  return (
    <BlockContainer>
      <ContentContainer>
        <Intersection>
          <InView>
            <TitleBlock>
              <h2>360 Service</h2>
            </TitleBlock>
            <ServiceList>
              {blocks.map((entry, index) => {
                const { icon, text } = entry;
                const isEven = (index + 1) % 2 === 0;
                const isLast = index === blocks.length - 1;
                return (
                  <li key={index}>
                    <div className="entry-content service">
                      <ServiceEntry
                        number={index + 1}
                        icon={icon}
                        text={text}
                        key={index}
                      />
                      <ArrowContainer even={isEven ? 1 : 0}>
                        {!isLast &&
                          (isEven ? <CurlyArrowUp /> : <CurlyArrowDown />)}
                      </ArrowContainer>
                    </div>
                  </li>
                );
              })}
            </ServiceList>
          </InView>
        </Intersection>
      </ContentContainer>
    </BlockContainer>
  );
};

export default ServiceBlock;
