import React, { Fragment } from "react";
import BlockContainer from "../../Core/Containers/BlockContainer";
import ContentContainer from "../../Core/Containers/ContentContainer";
import styled from "styled-components";
import breakpoints from "../../../styles/breakpoints";
import PromoModule from "../../Core/PromoModule";
import InView from "../../Core/InView";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 60px;

  @media (min-width: ${breakpoints.lg}) {
    gap: 100px;
  }
`;

const Intersection = styled.div`
  .reveal {
    opacity: 0;
    transition: 1s all ease;
    transform: translateY(10px);
  }
  .reveal.active {
    opacity: 1;
    transform: translateY(0%);
  }

  @media (min-width: ${breakpoints.lg}) {
    .reveal {
      opacity: 0;
      transition: 1s all ease;
      transform: ${(props) =>
        props.left ? "translateX(-2%)" : "translateX(2%)"};
    }
    .reveal.active {
      opacity: 1;
      transform: translateX(0%);
    }
  }
`;

const PromoBlock = ({ blocks }) => {
  return (
    <BlockContainer smMargin>
      <Grid>
        {blocks.map((promoModule, index) => {
          const {
            category,
            image,
            imagePosition,
            linkText,
            linkUrl,
            title,
            content
          } = promoModule;

          return (
            <Intersection key={index} left={imagePosition === "Left" ? 1 : 0}>
              <InView>
                <PromoModule
                  category={category ? category.category : null}
                  image={image}
                  imagePosition={imagePosition}
                  linkText={linkText}
                  linkUrl={linkUrl}
                  title={title}
                  content={content}
                />
              </InView>
            </Intersection>
          );
        })}
      </Grid>
    </BlockContainer>
  );
};

export default PromoBlock;
